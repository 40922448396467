import {getOrdinal} from './../helper-functions/getOrdinal'
import {MONTHS_SHORT} from './../constants/global-constants'

export const getFormattedDate = (dateObj, withOrdinalAsSupHtml = false) => {
  const fullYear = dateObj.getFullYear()
  const month = dateObj.getMonth()
  const date = dateObj.getDate()
  if (withOrdinalAsSupHtml) {
    return `${date}<sup>${getOrdinal(date)}</sup> ${MONTHS_SHORT[month]} ${fullYear}`
  } else {
    return `${date}${getOrdinal(date)} ${MONTHS_SHORT[month]} ${fullYear}`
  }
}
