import {graphql, useStaticQuery} from 'gatsby'
import Container from './container'
import Fade from 'react-reveal/Fade'
import PageHeading from './page-heading'
import PressItem from './press-item'
import React from 'react'
import styled from 'styled-components'
import TextBlock from './text-block'

export default function PressSection({cssModifier}) {
  const data = useStaticQuery(graphql`
    {
      prismicPressPage {
        data {
          page_heading {
            text
          }
          intro_text {
            html
          }
          limit_items
        }
      }
      allPrismicPressItem {
        edges {
          node {
            data {
              body_copy {
                html
              }
              date
              enabled
              external_link_text {
                text
              }
              internal_document_link {
                url
              }
              external_link_url {
                url
              }
              internal_document_link_text {
                text
              }
              press_item_heading {
                text
              }
              primary_image {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
              secondary_image {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
              tertiary_image {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
              pull_quote {
                html
              }
            }
          }
        }
      }
    }
  `)

  let pressItems = []
  for (let item of data.allPrismicPressItem.edges) {
    pressItems.push(item.node.data)
  }

  const prismicData = {
    pageHeading: data.prismicPressPage.data.page_heading.text,
    introText: data.prismicPressPage.data.intro_text.html,
    pressItems,
    limitItems: data.prismicPressPage.data.limit_items,
  }

  prismicData.pressItems = prismicData.pressItems.filter(item => item.enabled)
  prismicData.pressItems.sort(function (a, b) {
    return new Date(b.date) - new Date(a.date)
  })
  if (prismicData.limitItems !== 'Show them all') {
    prismicData.pressItems = prismicData.pressItems.slice(0, parseInt(prismicData.limitItems, 10))
  }

  return (
    <StyledComponent className={`site-section ${cssModifier ? `site-section--${cssModifier}` : ''}`}>
      <PageHeading heading={prismicData.pageHeading} />

      <Container type="wide">
        <div className="row">
          {prismicData.introText && (
            <div className="intro-text-wrapper">
              <TextBlock cssModifier="intro-text">
                <div dangerouslySetInnerHTML={{__html: prismicData.introText}} />
              </TextBlock>
            </div>
          )}
          <div className="press-items-wrapper">
            {prismicData.pressItems.map((item, index) => (
              <PressItem data={item} key={index} />
            ))}
          </div>
        </div>
      </Container>
    </StyledComponent>
  )
}

const StyledComponent = styled.section`
  margin-top: 2rem;

  .row {
    display: flex;
    flex-direction: column;
    margin: 0 auto 4rem auto;
    max-width: 90rem;
    width: 100%;
  }

  .intro-text-wrapper {
    margin-bottom: 4rem;
    max-width: 87rem;
  }

  .press-items-wrapper {
  }
`
