import {graphql, navigate} from 'gatsby'
import ContactSection from './../components/contact-section'
import HoldingPageHeroImage from './../components/holding-page-hero-image'
import Layout from './../components/layout'
import PressSection from './../components/press-section'
import React, {useState} from 'react'
import {HOLDING_PAGE_CSS_MODIFIER, PRESS_SECTION_CSS_MODIFIER, PAGE_ROOT_ELEMENT_ID} from './../constants/global-constants'
import {isBrowser} from '../helper-functions/is-browser'

export default props => {
  const {data} = props
  const prismicData = {
    showTheHoldingPage: data.prismicHoldingPage.data.show_the_holding_page,
    pressPageNavEnabled: data.prismicPressPage.data.show_in_navigation,
  }

  if (prismicData.pressPageNavEnabled) {
    return (
      <Layout
        documentTitlePrefix="PRESS"
        isIndexPage={false}
        pageName="press"
        showScrollToTopBtn={false}
        showTheHoldingPage={prismicData.showTheHoldingPage}
      >
        {prismicData.showTheHoldingPage ? (
          <>
            <HoldingPageHeroImage />
            <ContactSection cssModifier={HOLDING_PAGE_CSS_MODIFIER} />
          </>
        ) : (
          <>
            <PressSection cssModifier={PRESS_SECTION_CSS_MODIFIER} />
          </>
        )}
      </Layout>
    )
  } else {
    if (isBrowser()) {
      navigate('/')
    }
    return <div></div>
  }
}

export const pageQuery = graphql`
  {
    prismicHoldingPage {
      data {
        show_the_holding_page
      }
    }
    prismicPressPage {
      data {
        show_in_navigation
      }
    }
  }
`
